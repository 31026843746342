import { useState, useContext, useEffect } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import QRCode from "qrcode.react";
import { useSnackbar } from "notistack";
import moment from "moment"

import ConfigContext from "./ConfigContext";

function AcceptInvitationDetails(props) {
    const config = useContext(ConfigContext);
    const { enqueueSnackbar } = useSnackbar();

    let [inviteeName, setInviteeName] = useState("");
    let [inviteeMobile, setInviteeMobile] = useState("");
    let [inviteeCompany, setInviteeCompany] = useState("");
    let [inviteeVehicle, setInviteeVehicle] = useState("");
    let [isAccepted, setAccepted] = useState(false);
    let [isCancelled, setCancelled] = useState(false);

    useEffect(() => {
        setInviteeName(props.data.invitation.visitorName);

        if (moment().isAfter(props.data.invitation.validity, "day")) {
            setCancelled(true);
            return;
        }

        if (props.data.invitation.status === "Accepted" || props.data.invitation.status === "Checked-In") {
            setAccepted(true);
            setInviteeName(props.data.invitation.visitorInfo.name)
            setInviteeMobile(props.data.invitation.visitorInfo.mobileNumber);
            setInviteeCompany(props.data.invitation.visitorInfo.companyName);
            setInviteeVehicle(props.data.invitation.visitorInfo.vehicleNumber);
        } else if (props.data.invitation.status === "Cancelled") {
            setCancelled(true);
        }
    }, [props.data.invitation]);
    
    const verifyEmptyField = (state, name, visibilityName) => {
        const visibility = props.data.purpose.categoryTypes[0].pages.includes(visibilityName);
        if (!state && visibility) return { ok: false, errMsg: `${name} cannot be empty` };
        return { ok: true };
    }

    const doAccept = async () => {
        let verifications = [];
        verifications.push(verifyEmptyField(inviteeName, "Name", "name"));
        verifications.push(verifyEmptyField(inviteeMobile, "Mobile Number", "contact"));
        verifications.push(verifyEmptyField(inviteeCompany, "Company Name", "company"));

        for (let x=0; x<verifications.length; x++) {
            if (!verifications[x].ok) {
                enqueueSnackbar(verifications[x].errMsg, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });
                return;
            }
        }

        let visitorInfo = {
            name: inviteeName,
            mobileNumber: inviteeMobile,
            companyName: inviteeCompany,
            vehicleNumber: inviteeVehicle
        };

        let accept = await fetch(`${config.url}/invitee/accept/${props.inviteId}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(visitorInfo)
        });

        if (accept.status === 200) setAccepted(true);
    };

    return (
        isCancelled ?
            <Grid item xs={12} style={{textAlign: "center"}}>
                <h2>This invitation has expired / been cancelled by your host</h2>
            </Grid> 
        :
        <>
            { isAccepted
                ?
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <h2 style={{margin: 0}}>You've accepted this invitation!</h2>
                </Grid>
                :
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <h2 style={{marginTop: 0}}>You've been invited by {props.data.invitation.createdBy.name}!</h2>
                    <h4>Please fill up the information below to accept this invitation.</h4>
                </Grid>
            }

            <Grid item xs={12} style={{textAlign: "center"}}>
                <Typography variant="caption" color="textSecondary">Location</Typography>
                <h4 style={{marginBottom: "8px", marginTop: 0}}>{props.data.invitation.blockDisplay}, #{props.data.invitation.unit}</h4>
                <Typography variant="caption" color="textSecondary">Date</Typography>
                <h4 style={{marginTop: 0}}>{moment(props.data.invitation.validity).format("DD MMM YYYY")}</h4>
            </Grid>

            <Grid item xs={12}>
                <TextField label="Name" value={inviteeName} type="text" variant="outlined" fullWidth InputProps={{readOnly: true}} />
            </Grid>

            { ((isAccepted && inviteeMobile) || (!isAccepted && props.data.purpose.categoryTypes[0].pages.includes("contact"))) &&
                <Grid item xs={12}>
                    <TextField label="Mobile Number" value={inviteeMobile} type="tel" variant="outlined" fullWidth onChange={e => setInviteeMobile(e.target.value.toUpperCase())} InputProps={{readOnly: isAccepted}} />
                </Grid>
            }
            
            { ((isAccepted && inviteeCompany) || (!isAccepted && props.data.purpose.categoryTypes[0].pages.includes("company"))) &&
                <Grid item xs={12}>
                    <TextField label="Company Name" value={inviteeCompany} type="text" variant="outlined" fullWidth onChange={e => setInviteeCompany(e.target.value.toUpperCase())} InputProps={{readOnly: isAccepted}} />
                </Grid>
            }
            
            { ((isAccepted && inviteeVehicle) || (!isAccepted && props.data.purpose.categoryTypes[0].pages.includes("vehiclenumber"))) &&
                <Grid item xs={12}>
                    <TextField label="Vehicle Number (Optional)" value={inviteeVehicle} type="text" variant="outlined" fullWidth onChange={e => setInviteeVehicle(e.target.value.toUpperCase())} InputProps={{readOnly: isAccepted}} />
                </Grid>
            }

            { isAccepted
                ?
                <Grid item xs={12}>
                    <h4 style={{textAlign:"center"}}>Please present the QR code shown below to the security guard upon arrival</h4>
                    <QRCode value={JSON.stringify({type: "preregistered", id: props.data.invitation._id})} style={{margin: "auto", display: "block"}} />
                </Grid>
                :
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={doAccept}>Accept</Button>
                </Grid>
            }
        </>
    )
}

export default AcceptInvitationDetails;