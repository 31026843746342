import { Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

function InvitationDetail(props) {
    return (
        <Grid item xs={props.size || 12} zeroMinWidth>
            <Typography variant="caption" color="textSecondary">{props.title}</Typography>
            <Typography variant="body1" noWrap>
                { props.content !== undefined ? props.content : <Skeleton /> }
            </Typography>
        </Grid>
    );
}

export default InvitationDetail;