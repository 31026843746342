import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Tooltip, Chip } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info";
import Format from "date-fns/format";

import Mainframe from "./Mainframe";
import ConfigContext from "./ConfigContext";
import VerifyLoggedInEffect from "./VerifyLoggedInEffect";

function ViewInvitations() {
    const config = useContext(ConfigContext);
    const history = useHistory();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchInvitations = async () => {
            let response = await fetch(`${config.url}/invite`, {
                method: "GET",
                credentials: "include"
            });
    
            let result = await response.json();
            setData(result);
        };

        fetchInvitations();
    }, [config.url]);

    const viewDetail = (inviteId, hostId) => {
        history.push(`/invitation/view/${hostId}/${inviteId}`);
    };

    return (
        <Mainframe>
            <VerifyLoggedInEffect />
            
            <Grid item xs={12}>
                <h4 style={{textAlign: "center", margin: "0px"}}>Click <InfoIcon color="primary" fontSize="small" /> for more details</h4>
            </Grid>

            <Grid item xs={12} style={{textAlign: "center"}}>
                <h5 style={{margin: "10px"}}>Status Legend</h5>
                <Chip label="Pending" size="small" style={{backgroundColor: "#ffe0b2", margin: "3px"}} />
                <Chip label="Accepted" size="small" style={{backgroundColor: "#c8e6c9", margin: "3px"}} />
                <Chip label="Checked-In" size="small" style={{backgroundColor: "#bbdefb", margin: "3px"}} />
            </Grid>

            <Grid item xs={12}>
                <List component="nav">
                    { data.map((item, idx) => (
                        <ListItem key={item._id} divider style={{
                            backgroundColor: item.status === "Accepted" ? "#c8e6c9" : 
                                            item.status === "Checked-In" ? "#bbdefb" : 
                                            "#ffe0b2", 
                            border: "2px dashed grey",
                            marginTop: idx === 0 ? "0px" : "10px"}}
                        >
                            <ListItemText primary={item.visitorName} secondary={`Valid on ${Format(new Date(item.validity), "dd MMM yyyy")}`} />
                            <ListItemSecondaryAction>
                                <IconButton color="primary" edge="end" onClick={ () => viewDetail(item._id, item.createdBy) }>
                                    <Tooltip title="See More" arrow><InfoIcon /></Tooltip>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Grid>

            <Grid item xs={12}>
                <Button variant="outlined" color="secondary" fullWidth onClick={ () => history.push("/home") }>Cancel</Button>
            </Grid>
        </Mainframe>
    );
}

export default ViewInvitations;