import { useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

import Mainframe from "./Mainframe";
import ConfigContext from "./ConfigContext";
import VerifyLoggedInEffect from "./VerifyLoggedInEffect";

function Main() {
    const history = useHistory();
    const config = useContext(ConfigContext);

    const doLogout = async () => {
        await fetch(`${config.url}/auth/logout`, {
            method: "GET",
            credentials: "include"
        });
        history.push("/login");
    }
    
    return (
        <Mainframe>
            <VerifyLoggedInEffect />

            <Grid item xs={12}>
                <h2 style={{fontSize: "1.3em", margin: "0px"}}>Hi, {localStorage.userName}<EditIcon color="primary" style={{ marginLeft: "10px" }} onClick={ () => history.push("/profile") } /></h2>
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" color="primary" size="large" fullWidth onClick={ () => history.push("/invitation/send") }>Send Invitation</Button>
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" size="large" fullWidth onClick={ () => history.push("/invitation/view") }>View Invitation(s)</Button>
            </Grid>

            <Grid item xs={12}>
                <Button variant="outlined" color="secondary" size="large" fullWidth onClick={ doLogout }>Logout</Button>
            </Grid>
        </Mainframe>
    );
}

export default Main;