import { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core"

import Mainframe from "./Mainframe";
import VerifyLoggedInEffect from "./VerifyLoggedInEffect";
import ConfigContext from "./ConfigContext";
import InvitationDetail from "./InvitationDetail";

function Profile() {
    const history = useHistory();
    const config = useContext(ConfigContext);

    const [profile, setProfile] = useState({});

    useEffect(() => {
        const fetchProfile = async () => {
            let response = await fetch(`${config.url}/user/profile`, {
                method: "GET",
                credentials: "include"
            });

            let profileData = await response.json();
            setProfile(profileData);
        };
        fetchProfile();
    }, [config.url]);

    return (
        <Mainframe>
            <VerifyLoggedInEffect />

            <InvitationDetail title="Account Type" content={profile.type} />
            <InvitationDetail title="Name" content={profile.name} />
            <InvitationDetail title="Block" size={6} content={profile.blockDisplay} />
            <InvitationDetail title="Unit" size={6} content={profile.unit} />
            <InvitationDetail title="Mobile No." content={profile.mobile} />
            <InvitationDetail title="Email Address" content={profile.email} />

            <Grid item xs={12}>
                <Button variant="contained" color="primary" fullWidth onClick={ () => history.push("/profile/password") }>Change Password</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" color="secondary" fullWidth onClick={ () => history.push("/home") }>Back</Button>
            </Grid>
        </Mainframe>
    );
}

export default Profile;