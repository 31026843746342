import { useState, useContext } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import Mainframe from "./Mainframe";
import ConfigContext from "./ConfigContext";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const config = useContext(ConfigContext);

    const doLogin = async () => {
        try {
            let postBody = { email, password, siteId: config.site };

            let auth = await fetch(`${config.url}/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(postBody)
            });

            let authJson = await auth.json();

            if (auth.status === 200) {
                enqueueSnackbar(`Welcome, ${authJson.authUser.name}`, { 
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });

                localStorage.userSalutation = authJson.authUser.salutation;
                localStorage.userName = authJson.authUser.name;

                //setTimeout(() => history.push("/home"), 2000);
                history.push("/home");
            } else if (auth.status === 400) {
                enqueueSnackbar(authJson.msg, { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Mainframe>
            <Grid item xs={12}>
                <TextField label="Email Address" type="email" variant="outlined" fullWidth onChange={ e => setEmail(e.target.value) } />
            </Grid>
            <Grid item xs={12}>
                <TextField label="Password" type="password" variant="outlined" fullWidth onChange={ e => setPassword(e.target.value) } />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" fullWidth onClick={doLogin}>Login</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={ () => history.push("/create") }>Create New Account</Button>
            </Grid>
        </Mainframe>
    );
}

export default Login;