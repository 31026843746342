import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useContext } from "react";

import ConfigContext from "./ConfigContext";

function Mainframe(props) {
    const config = useContext(ConfigContext);

    return (
        <Container maxWidth="sm" style={{marginTop: "5vh", padding: "30px"}}>        
            <Grid container spacing={3} style={{ border: "1px solid grey", borderRadius: "20px", padding: "30px" }}>
                <Grid item xs={12}>
                    <img src={`https://assets.mpolis.sg/${config.site}/logo.png`} alt="logo" style={{width: "30vw", minWidth: "200px", maxWidth:"300px", margin: "auto", display: "block"}} />
                </Grid>

                {props.children}
            </Grid>

            <p style={{color: "grey", fontSize: "0.7em", padding: "10px", textAlign: "center"}}>Brought to you by <a href="https://www.metropolis.sg">Metropolis Security Systems Pte Ltd</a></p>
        </Container>
    );
}
export default Mainframe;