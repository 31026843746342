import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, TextField, Button } from "@material-ui/core";

import Mainframe from "./Mainframe";
import ConfigContext from "./ConfigContext";
import VerifyLoggedInEffect from "./VerifyLoggedInEffect";

function ChangePassword() {
    const history = useHistory();
    const config = useContext(ConfigContext);
    const { enqueueSnackbar } = useSnackbar();
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const changePassword = async () => {
        let errorMsg = "";

        if (!password) errorMsg = "Enter a password";
        else if (!password2) errorMsg = "Confirm the password";
        else if (password !== password2) errorMsg = "Password mismatch";
        
        if (errorMsg) {
            enqueueSnackbar(errorMsg, {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
            return false;
        }

        let postBody = { password };

        let response = await fetch(`${config.url}/user/password`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postBody)
        });

        if (response.status === 200) {
            setPassword("");
            setPassword2("");

            enqueueSnackbar("Password changed!", {
                variant: "success",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
        }
    };

    return (
        <Mainframe>
            <VerifyLoggedInEffect />

            <Grid item xs={12}>
                <TextField label="Enter New Password" variant="outlined" type="password" value={password} fullWidth onChange={ e => setPassword(e.target.value) } />
            </Grid>
            <Grid item xs={12}>
                <TextField label="Confirm New Password" variant="outlined" type="password" value={password2} fullWidth onChange={ e => setPassword2(e.target.value) } />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" fullWidth onClick={changePassword}>Change</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" color="secondary" fullWidth onClick={ () => history.push("/profile") }>Back</Button>
            </Grid>
        </Mainframe>
    );
}

export default ChangePassword;