import { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom"
import { useSnackbar } from "notistack";

import Mainframe from "./Mainframe";
import ConfigContext from "./ConfigContext";
import AcceptInvitationDetails from "./AcceptInvitationDetails";

function AcceptInvitation() {
    const match = useRouteMatch("/invitation/accept/:inviteId");
    const inviteId = match.params.inviteId;
    const config = useContext(ConfigContext);
    const { enqueueSnackbar } = useSnackbar();

    let [data, setData] = useState({
        invitation: {
            visitorName: "",
            createdBy: {
                name: ""
            }
        },
        purpose: {
            categoryTypes: [
                {
                    pages: []
                }
            ]
        }
    });

    let [isValid, setValid] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            let response = await fetch(`${config.url}/invitee/${inviteId}`);
            let jsonData = await response.json();

            if (response.status !== 200) {
                enqueueSnackbar(jsonData.msg, { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });
                return setValid(false);
            }

            setData(jsonData);
        };

        fetchData();
    }, [config.url, inviteId, enqueueSnackbar]);


    let display = <></>

    if (isValid) {
        display = <AcceptInvitationDetails data={data} inviteId={inviteId} />;
    } else {
        display = (
            <>
                <h3>This invitation link is not valid.</h3>
                <h3>For more information, please contact your host.</h3>
            </>
        );
    }

    return (
        <Mainframe>
            { display }
        </Mainframe>
    );
}

export default AcceptInvitation;