import { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Login from "./Login";
import Main from "./Main";
import SendInvitation from "./SendInvitation";
import ViewInvitations from "./ViewInvitations";
import ViewInvitationDetails from "./ViewInvitationDetails";
import AcceptInvitation from "./AcceptInvitation";
import NewAccount from "./NewAccount";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";

import ConfigContext from "./ConfigContext";

function Routes() {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];

    const config = useContext(ConfigContext);
    config.site = subdomain.toUpperCase();
    config.org = "MSS";

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/login" />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/create">
                    <NewAccount />
                </Route>
                <Route path="/home">
                    <Main />
                </Route>
                <Route path="/profile/password">
                    <ChangePassword />
                </Route>
                <Route path="/profile">
                    <Profile />
                </Route>
                <Route path="/invitation/send">
                    <SendInvitation />
                </Route>
                <Route path="/invitation/view/:hostId/:inviteId">
                    <ViewInvitationDetails />
                </Route>
                <Route path="/invitation/view">
                    <ViewInvitations />
                </Route>
                <Route path="/invitation/accept/:inviteId">
                    <AcceptInvitation />
                </Route>
                <Route path="*">
                    <Redirect to="/home" />
                </Route>
            </Switch>
        </Router>
    );
}

export default Routes;