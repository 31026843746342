import { useState, useEffect, useContext } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Grid, Button, Typography, TextField, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { WhatsappShareButton, EmailShareButton, TelegramShareButton, LineShareButton, WhatsappIcon, EmailIcon, TelegramIcon, LineIcon } from "react-share";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { useSnackbar } from "notistack";
import Skeleton from "@material-ui/lab/Skeleton";

import Mainframe from "./Mainframe";
import ConfigContext from "./ConfigContext";
import InvitationDetail from "./InvitationDetail";
import VerifyLoggedInEffect from "./VerifyLoggedInEffect";

function ViewInvitationDetails() {
    const config = useContext(ConfigContext);
    const match = useRouteMatch("/invitation/view/:hostId/:inviteId");
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState({});
    const [isShareBtnDisabled, setShareBtnDisabled] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);

    const copyLinkToClipboard = (val) => {
        navigator.clipboard.writeText(val);

        enqueueSnackbar("Copied to clipboard", {
            variant: "info",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
        });
    };

    const doCancellation = async () => {
        let response = await fetch(`${config.url}/invite/cancel`, {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ inviteId: data._id })
        });

        if (response.status === 200) {
            enqueueSnackbar("Invitation cancelled", {
                variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
            });

            history.push("/invitation/view");
            return;
        }

        let json = await response.json();

        enqueueSnackbar(json.msg, {
            variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
        });
    };

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    useEffect(() => {
        const fetchData = async () => {
            const hostId = match.params.hostId;
            const inviteId = match.params.inviteId;

            let response = await fetch(`${config.url}/invite/host/${hostId}/invite/${inviteId}`, {
                method: "GET",
                credentials: "include"
            });

            let inviteData = await response.json();
            setData(inviteData);
            setShareBtnDisabled(false);
        };
        fetchData();
    }, [config.url, match.params.hostId, match.params.inviteId]);

    return (
        <Mainframe>
            <VerifyLoggedInEffect />

            <InvitationDetail title="Visit Purpose" content={data.visitorPurpose} />
            <InvitationDetail title="Name" content={data.visitorName} />
            {/* <InvitationDetail title="Email Address" content={data.visitorEmail} /> */}
            <InvitationDetail title="Status" content={data.status} />
            {/* <InvitationDetail title="Invitation Link" content={<a href={`../../accept/${data._id}`} target="_blank" rel="noreferrer">{`${window.location.origin}/invitation/accept/${data._id}`}</a>} /> */}

            <Grid item xs={12} zeroMinWidth>
                <Typography variant="caption" color="textSecondary">Invitation Link</Typography>
                { data._id !== undefined ? <TextField variant="standard" fullWidth 
                    value={`${window.location.origin}/invitation/accept/${data._id}`} 
                    InputProps={{ 
                        endAdornment: <InputAdornment position="end"><FilterNoneIcon fontSize="small" color="action" onClick={ () => copyLinkToClipboard(`${window.location.origin}/invitation/accept/${data._id}`) } /></InputAdornment> 
                    }} 
                /> : <Skeleton /> }
            </Grid>

            <Grid item xs={12} style={{paddingBottom: "0px"}}>
                <Typography variant="caption" color="textSecondary">Share</Typography>
            </Grid>
            <Grid item xs={12} style={{paddingTop: "0px"}}>
                <WhatsappShareButton url={`${window.location.origin}/invitation/accept/${data._id}`} title={"Please accept my invitation."} separator={"\n"} disabled={isShareBtnDisabled}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>

                <TelegramShareButton url={`${window.location.origin}/invitation/accept/${data._id}`} title={"Please accept my invitation."} disabled={isShareBtnDisabled}>
                    <TelegramIcon size={32} round={true} />
                </TelegramShareButton>

                <LineShareButton url={`${window.location.origin}/invitation/accept/${data._id}`} title={"Please accept my invitation."} disabled={isShareBtnDisabled}>
                    <LineIcon size={32} round={true} />
                </LineShareButton>

                <EmailShareButton url={`${window.location.origin}/invitation/accept/${data._id}`} subject={"You're Invited!"} body="Please accept the invitation by clicking on the following link." separator={"\n"} disabled={isShareBtnDisabled}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" color="secondary" fullWidth onClick={handleDialogOpen}>Cancel Invitation</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" color="secondary" fullWidth onClick={() => history.goBack()}>Back</Button>
            </Grid>

            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText>Are you sure you want to cancel this invitation?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={doCancellation}>Yes</Button>
                    <Button color="secondary" onClick={handleDialogClose}>No</Button>
                </DialogActions>
            </Dialog>
        </Mainframe>
    );
}

export default ViewInvitationDetails;