import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, InputLabel, MenuItem } from "@material-ui/core"
import { useSnackbar } from "notistack";

import Mainframe from "./Mainframe";
import ConfigContext from "./ConfigContext";

function NewAccount() {
    const config = useContext(ConfigContext);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [type, setType] = useState("");
    const [block, setBlock] = useState("");
    const [blockDisplay, setBlockDisplay] = useState("");
    const [unit, setUnit] = useState("");
    const [blockData, setBlockData] = useState([]);
    const [blockList, setBlockList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        const fetchBlocks = async () => {
            let response = await fetch(`${config.url}/guest/${config.org}/${config.site}/blocks`);
            let data = await response.json();
            setBlockData(data);
            let blockMenuItems = getBlockList(data);
            setBlockList(blockMenuItems);
        };
        fetchBlocks();
    }, [config]);

    const getBlockList = blocks => blocks
        .filter(block => block.units.length > 0)
        .map(block => <MenuItem value={block.blockId} key={block._id}>{block.blockDisplayName}</MenuItem>);

    const handleTypeChange = e => {
        const residentType = e.target.value;
        setType(residentType);
    };

    const handleBlockChange = e => {
        const blkId = e.target.value;
        const blkDisplay = e.nativeEvent.target.innerText;

        setBlock(blkId);
        setBlockDisplay(blkDisplay);
        
        setUnit("");
        
        const blkFilter = blockData.filter(block => block.blockId === blkId);
        setUnitList(blkFilter[0].units.map(unit => <MenuItem value={unit} key={unit}>{unit}</MenuItem>));
    };

    const handleUnitChange = e => {
        const selectedUnit = e.target.value;
        setUnit(selectedUnit);
    }

    const handleTextChange = (e, setHandler) => setHandler(e.target.value);

    const verifySubmit = details => {
        for (let key in details) {
            if (!details[key]) return { ok: false, msg: "All fields are mandatory" };
        }

        if (details.password.length < 6) return { ok: false, msg: "Password length must be at least 6" };
        if (details.password !== details.password2) return { ok: false, msg: "Please confirm your password" };

        return { ok: true, msg: null };
    };

    const handleSubmit = async () => {      
        const accountDetails = {
            orgId: config.org,
            siteId: config.site,
            type,
            block,
            blockDisplay,
            unit,
            name,
            email,
            mobile,
            password,
            password2
        };

        const { ok, msg } = verifySubmit(accountDetails);

        if (!ok) {
            enqueueSnackbar(msg, {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
            return;
        }


        let response = await fetch(`${config.url}/guest/resident`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(accountDetails)
        });

        if (response.status !== 201) {
            let json = await response.json();
            enqueueSnackbar(json.msg, {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
            return;
        }

        setIsRegistered(true);
    };

    return (
        <Mainframe>
            { isRegistered ? 
                <>
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <h2 style={{margin: 0}}>You are registered successfully!</h2>
                        <h4>Please await confirmation from your managing agent</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="secondary" fullWidth onClick={ () => history.push("/login") }>Back To Home</Button>
                    </Grid>
                </>
                :
                <>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Type</FormLabel>
                            <RadioGroup name="type1" row value={type} onChange={handleTypeChange}>
                                <FormControlLabel value="Owner" control={<Radio color="primary" />} label="Owner" />
                                <FormControlLabel value="Tenant" control={<Radio color="primary" />} label="Tenant" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="block-label">Block</InputLabel>
                            <Select labelId="block-label" label="Block" value={block} defaultValue="" onChange={handleBlockChange}>
                                <MenuItem value="" disabled></MenuItem>
                                { blockList }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="unit-label">Unit</InputLabel>
                            <Select labelId="unit-label" label="Unit" value={unit} defaultValue="" onChange={handleUnitChange}>
                                <MenuItem value="" disabled></MenuItem>
                                { unitList }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Name" type="text" variant="outlined" value={name} onChange={e => handleTextChange(e, setName)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Email Address" type="email" variant="outlined" value={email} onChange={e => handleTextChange(e, setEmail)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Mobile No." type="text" variant="outlined" value={mobile} onChange={e => handleTextChange(e, setMobile)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Password" type="password" variant="outlined" value={password} onChange={e => handleTextChange(e, setPassword)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Confirm Password" type="password" variant="outlined" value={password2} onChange={e => handleTextChange(e, setPassword2)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>Submit</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="secondary" fullWidth onClick={ () => history.push("/login") }>Cancel</Button>
                    </Grid>
                </>
            }
        </Mainframe>
    );
}

export default NewAccount;