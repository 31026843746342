import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import ConfigContext from "./ConfigContext";

function VerifyLoggedInEffect() {
    const [isLoggedIn, setLoggedIn] = useState(null);
    const history = useHistory();
    const config = useContext(ConfigContext);

    useEffect(() => {
        const verify = async () => {
            let fetchResult = await fetch(`${config.url}/auth/verify`, {
                method: "POST",
                credentials: "include",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ siteId: config.site })
            });

            let response = await fetchResult.json();
            let loggedInStatus = response.cookieExist;
            setLoggedIn(loggedInStatus);
        };
        verify();
    }, [config.url, config.site]);

    useEffect(() => {
        if (isLoggedIn !== null && !isLoggedIn) history.replace("/login");
    }, [isLoggedIn, history]);

    return null;
}

export default VerifyLoggedInEffect;