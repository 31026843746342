import React from "react";

const config = {
    dev: {
        url: "http://localhost:5100",
    },
    staging: {
        url: "https://resident4738.dev.mpolis.sg",
    },
    prod: {
        url: "https://resident-backend-473647.mpolis.sg"
    }
}

let loadConfig;

if (process.env.NODE_ENV === "development") loadConfig = config.dev;
else if (process.env.NODE_ENV === "production") loadConfig = config.prod;

const ConfigContext = React.createContext(loadConfig);

export default ConfigContext;
